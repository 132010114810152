import React, { useState, useEffect } from "react"
import { Container, Row, Col, Table, Form } from "react-bootstrap"
import firebase from "gatsby-plugin-firebase"

const EditUsers = ({ user, location }) => {
  const [fbUsers, setFbUsers] = useState({})
  const [sortedUsers, setSortedUsers] = useState([])
  const [fbUser, setFbUser] = useState({})
  const [fbOrg, setFbOrgData] = useState({})
  const [approved, setApproved] = useState({})
  const [admin, setAdmin] = useState({})
  const [token, setToken] = useState(null)

  const handleApprovedToggle = e => {
    const id = e.target.id
    const key = id.split("-")[1]

    firebase
      .database()
      .ref("/users/" + key)
      .set({ ...fbUsers[key], approved: !approved[key] })
    setApproved(prevState => {
      return {
        ...prevState,
        [key]: !approved[key],
      }
    })

    //message 2
    const approvedUser = fbUsers[key]

    const dataToUser = {
      fromName: "POCAM Admin",
      fromEmail: "noreply@wearepocam.ca",
      toEmail: approvedUser.email,
      subject: "The Call for Equity Progress Tracker Access - Approved",
      message: `
        Hi ${approvedUser.name},

        POCAM has reviewed your request to access The Call for Equity Progress Tracker.

        You can now sign in at ${location.origin + "/update-status"}

        Thank you
      `,
    }

    e.target.checked && sendEmail(dataToUser)
  }

  const handleAdminToggle = async e => {
    const id = e.target.id
    const key = id.split("-")[1]
    const data = {
      toAdminEmail: fbUsers[key].email,
      token: token,
    }

    postData("/.netlify/functions/setAdmin", data).then(data => {
      // console.log(data)
      let admin = false

      if (data.data === "add admin") {
        admin = true
      }

      firebase
        .database()
        .ref("/users/" + key)
        .set({ ...fbUsers[key], admin: admin })

      setAdmin(prevState => {
        return {
          ...prevState,
          [key]: admin,
        }
      })
    })
  }

  const postData = async (url, data) => {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
    })
    return response.json()
  }

  const sendEmail = async (data, e) => {
    try {
      const response = await fetch("/.netlify/functions/sendEmail", {
        method: "POST",
        body: JSON.stringify(data),
      })

      if (!response.ok) {
        //not 200 response
        return
      }
      //all OK
      // customEvent(`${getCurrentDate()}`, 'Send Email', `${userEmail} Send Email Success`);
      console.log("email sent")
    } catch (e) {
      // customEvent(`${getCurrentDate()}`, 'Send Email', `${userEmail} Send Email Fail`);
      //error
      console.log(e)
    }
  }

  useEffect(() => {
    // console.log('useEffect');
    //Get all users
    firebase
      .database()
      .ref("/users/")
      .on("value", snapshot => {
        const val = snapshot.val()
        
        // console.log(val);
        let sorted = Object.keys(val).sort((a, b) => {
          return val[a].company.localeCompare(val[b].company)
        })
        setSortedUsers(sorted)
        setFbUsers(val)

        Object.keys(val).forEach((key, index) => {
          setApproved(prevState => {
            return {
              ...prevState,
              [key]: val[key].approved,
            }
          })

          setAdmin(prevState => {
            return {
              ...prevState,
              [key]: val[key].admin,
            }
          })
        })
      })

    //Get logged in user values
    firebase
      .database()
      .ref("/users/" + user.uid)
      .on("value", snapshot => {
        const val = snapshot.val()
        setFbUser(val)
      })

    // get claims
    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then(idTokenResult => {
        // Confirm the user is an Admin.

        if (!!idTokenResult.claims.admin) {
          // Show admin UI.
          //  showAdminUI();
          setToken(idTokenResult.token)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  // useEffect(() => {
  //   console.log(token)
  // }, [token])

  return (
    <Container>
      {!token ? (
        <Row>
          <Col>
            <h1 className="h2 mb-3">Hey {fbUser.name}.</h1>
            <p>
              You don't have access to this section. If that's an error, please
              contact{" "}
              <a href="mailto:wearepocam@gmail.com" refferer="norefferer">
                wearepocam@gmail.com
              </a>
            </p>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md="6" className="mb-4">
            <h1 className="h2 mb-3">Welcome back {fbUser.name}.</h1>
            <p>You are logged in as an admin.</p>
            <p>
              Please review a users linkedin and email address, before
              approving.
            </p>
          </Col>

          <Col>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Company</th>
                  <th>Role</th>
                  <th>Status</th>
                  <th>Admin</th>
                </tr>
              </thead>
              <tbody>
                {sortedUsers.map((key, index) => {
                  const user = fbUsers[key]
                  if (!user) {
                    return
                  }
                  return (
                    <tr key={key}>
                      <td>
                        <a
                          href={user.linkedin}
                          target="_blank"
                          referrer="noreferrer"
                        >
                          {user.name}
                        </a>
                      </td>
                      <td>{user.email}</td>
                      <td>{user.company}</td>
                      <td>{user.role}</td>
                      <td>
                        <Form>
                          <Form.Check
                            type="switch"
                            id={"approved-" + key}
                            key={key}
                            name="approved"
                            label={approved[key] ? "Approved" : "Not Approved"}
                            checked={approved[key] || ""}
                            onChange={handleApprovedToggle}
                          />
                        </Form>
                      </td>
                      <td>
                        <Form>
                          <Form.Check
                            type="switch"
                            id={"admin-" + key}
                            key={key}
                            name="admin"
                            label={admin[key] ? "Yes" : "No"}
                            checked={admin[key] || ""}
                            onChange={handleAdminToggle}
                          />
                        </Form>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </Container>
  )
}

export default EditUsers
